<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ customer.company_name }} - {{ $t("labels.config") }}
      <v-spacer></v-spacer>
      <v-btn color="success" @click="updateConfig" class="mr-2">
        {{ $t("labels.save") }}
      </v-btn>
      <v-btn color="error" outlined @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div class="fs-16px font-weight-medium primary--text mb-3">
        {{ $t("labels.goods") }}
      </div>

      <v-row dense>
        <v-col
          cols="12"
          md="4"
          v-for="(item, index) in goodsConfigs"
          :key="`rc_${index}`"
        >
          <v-autocomplete
            v-model="item.value"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
            :items="yesNoOptions"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div class="fs-16px font-weight-medium primary--text mb-3">
        {{ $t("labels.stamp") }}
      </div>

      <v-row dense>
        <v-col
          cols="12"
          md="4"
          v-for="(item, index) in stampConfigs"
          :key="`rc_${index}`"
        >
          <v-autocomplete
            v-if="['size'].includes(item.key)"
            v-model="item.value"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
            :items="stampOptions"
          ></v-autocomplete>
          <v-autocomplete
            v-else-if="['show_barcode_in_stamp'].includes(item.key)"
            v-model="item.value"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
            :items="yesNoOptions"
          ></v-autocomplete>
          <v-text-field
            v-else
            v-model.number="item.value"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div class="fs-16px font-weight-medium primary--text mb-3">
        {{ $t("labels.delivery") }}
      </div>
      <v-row dense>
        <v-col
          cols="12"
          md="4"
          v-for="(item, index) in deliveryConfigs"
          :key="`dec_${index}`"
        >
          <v-autocomplete
            v-model="item.value"
            :items="yesNoOptions"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div class="fs-16px font-weight-medium primary--text mb-3">
        {{ $t("labels.order_stamp") }}
      </div>

      <v-row dense>
        <v-col
          cols="12"
          md="4"
          v-for="(item, index) in orderStampConfigs"
          :key="`osc_${index}`"
        >
          <v-autocomplete
            v-model="item.value"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
            :items="yesNoOptions"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS, DELIVERY_CONFIG } from "@/libs/const";

export default {
  name: "DialogConfig",
  components: {},
  props: {
    customer: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    yesNoOptions: [...YES_NO_OPTIONS],
    deliveryConfigs: [...DELIVERY_CONFIG],
    goodsConfigs: [
      {
        key: "no_label",
        value: 0,
        display: "No Label",
        type: "goods",
      },
      {
        key: "uid_printed_on_item",
        value: 0,
        display: "UID Printed On Item",
        type: "goods",
      },
      {
        key: "pack_show_qr_code",
        value: 1,
        display: "QR code SKU khi đóng gói",
        type: "goods",
      },
      {
        key: "is_allow_pick_by_location",
        value: 0,
        display: "Pickup bằng vị trí",
        type: "goods",
      },
      {
        key: "pack_scan_required",
        value: 1,
        display: "Scan khi đóng gói",
        type: "goods",
      },
    ],
    stampOptions: [
      {
        text: "50x20",
        value: "50x20",
      },
      {
        text: "32x18",
        value: "32x18",
      },
      {
        text: "30x20",
        value: "30x20",
      },
      {
        text: "30x15",
        value: "30x15",
      },
    ],
    stampConfigs: [
      {
        key: "size",
        value: "50x20",
        display: "Size",
        type: "stamp",
      },
      {
        key: "max_print_quantity",
        value: 200,
        display: "In tối đa",
        type: "stamp",
      },
      {
        key: "show_barcode_in_stamp",
        value: 1,
        display: "In mã Barcode khi tạo tem",
        type: "stamp",
      },
    ],
    orderStampConfigs: [
      {
        key: "hide_phone_number",
        value: 0,
        display: "Ẩn SĐT",
        type: "order_stamp",
      },
    ],
  }),
  computed: {},
  watch: {},
  created() {
    if (this.customer.cutoff_by) {
      this.cutoff_by = this.customer.cutoff_by;
    }
    if (this.customer.cutoff_time) {
      this.cutoff_time = this.customer.cutoff_time;
    }
    if (this.customer.configs) {
      const workConfigs = JSON.parse(this.customer.configs);
      const goodsConfigs = [...workConfigs].filter((wk) => wk.type === "goods");
      const stampConfigs = [...workConfigs].filter((wk) => wk.type === "stamp");
      const orderStampConfigs = [...workConfigs].filter(
        (wk) => wk.type === "order_stamp"
      );
      const deliveryConfigs = [...workConfigs].filter(
        (wk) => wk.type === "delivery"
      );

      if (goodsConfigs && goodsConfigs.length > 0) {
        this.goodsConfigs = [...this.goodsConfigs].map((gc) => {
          const goodsConfig = [...goodsConfigs].find(
            (gc1) => gc1.key == gc.key
          );
          if (goodsConfig) {
            gc.value = goodsConfig.value;
          }

          return gc;
        });
      }
      if (stampConfigs && stampConfigs.length > 0) {
        this.stampConfigs = [...stampConfigs];
      }
      if (orderStampConfigs && orderStampConfigs.length > 0) {
        this.orderStampConfigs = [...orderStampConfigs];
      }
      if (deliveryConfigs && deliveryConfigs.length > 0) {
        this.deliveryConfigs = [...deliveryConfigs];
      }
    }
  },
  mounted() {},
  methods: {
    cancel() {
      this.$emit("closeDialog");
    },
    async updateConfig() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/customer/v1/save-config", {
          id: this.customer.id,
          configs: [
            ...this.goodsConfigs,
            ...this.stampConfigs,
            ...this.orderStampConfigs,
            ...this.deliveryConfigs,
          ],
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
